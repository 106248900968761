
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { PreRegisterFindAll } from '@/wms/application/preRegister/search/PreRegisterFindAll';
import { PreRegisterStatusEnum } from '@/wms/domain/preRegister/PreRegisterStatusEnum';
import AddWarehousePreRegister from '@/wms/infrastructure/ui/warehousePreRegister/AddWarehousePreRegister.vue';
import { PreRegisterFindByPk } from '@/wms/application/preRegister/search/PreRegisterFindByPk';
import { PreRegisterTypeEnum } from '@/wms/domain/preRegister/PreRegisterTypeEnum';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { getFormattedDate } from '@/core/plugins/DateFormatter';
import { PreRegisterFindByDateAndLikeType } from '@/wms/application/preRegister/search/PreRegisterFindByDateAndLikeType';
import PreRegisterDocument from './preRegisterDocument/PreRegisterDocument.vue';

interface Filters {
  from: string;
  to: string;
  type: { id: string; name: string };
}

@Component({
  name: 'WarehousePreRegisterList',
  components: { CustomTableN, AddWarehousePreRegister, PreRegisterDocument }
})
export default class WarehousePreRegisterList extends Vue {
  @Inject(TYPES.PRE_REGISTER_FINDALL)
  readonly findAllPreRegister!: PreRegisterFindAll;
  @Inject(TYPES.PRE_REGISTER_FIND_BY_PK)
  readonly findByPk!: PreRegisterFindByPk;
  @Inject(TYPES.PRE_REGISTER_FINDALL_BY_DATE_AND_LIKE_TYPE)
  readonly findAllByDateAndType!: PreRegisterFindByDateAndLikeType;
  //Listas
  preRegisterList: PreRegister[] = [];

  //Data
  isLoading = false;
  form: PreRegister = new PreRegister();
  showModal = false;
  typeOptions = [
    {
      id: PreRegisterTypeEnum.SHIPMENT,
      name: `${this.$t('general.shipment')}`
    },
    {
      id: PreRegisterTypeEnum.PRE_REGISTER,
      name: `${this.$t('general.pre_register')}`
    },
    {
      id: PreRegisterTypeEnum.WAREHOUSE_RECEIPT,
      name: `${this.$t('general.warehouseReceipt')}`
    },
    {
      id: PreRegisterTypeEnum.EXPEDITION_PRE_REGISTER,
      name: `${this.$t('general.warehouseDispatch')}`
    }
  ];

  filters: Filters = {
    from: getFormattedDate(new Date()),
    to: getFormattedDate(new Date()),
    type: {
      id: PreRegisterTypeEnum.PRE_REGISTER,
      name: `${this.$t('general.pre_register')}`
    }
  };

  //Ciclo de vida del componente
  async mounted() {
    await this.findAll();
  }

  //Objetos de acciones
  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        action: this.edit,
        variant: 'secondary'
      },
      {
        title: `${this.$t('general.details')}`,
        icon: 'fa fa-eye',
        action: this.show,
        variant: 'warning'
      },
      {
        title: `${this.$t('menu.historyPanel')}`,
        icon: 'fa fa-road',
        action: this.getPreRegisterByNumberAndType,
        variant: 'success'
      },
      {
        title: `${this.$t('general.seeDocument')}`,
        icon: 'fa fa-file',
        action: this.seeDocument,
        variant: 'primary'
      }
    ]
  };

  //Getter para obtener los campos de la tabla
  get fields() {
    return [
      {
        label: `${this.$t('general.number')}`,
        field: 'number',
        sortable: true
      },
      {
        label: `${this.$t('general.type')}`,
        field: 'type',
        sortable: true,
        formatFn: (item: string) => {
          if (item.includes(PreRegisterTypeEnum.WAREHOUSE_RECEIPT)) {
            return `${this.$t('general.warehouseReceipt')}`;
          }
          if (item.includes(PreRegisterTypeEnum.PRE_REGISTER)) {
            return `${this.$t('general.pre_register')}`;
          }
        }
      },
      {
        label: `${this.$t('general.trackingnumber')}`,
        field: 'trackingNumber.trackingNumber',
        sortable: true
      },
      {
        label: `${this.$t('general.sender')}`,
        field: 'sender.fullName',
        sortable: true
      },
      {
        label: `${this.$t('general.recipient')}`,
        field: 'consignee.name',
        sortable: true
      },
      {
        label: `${this.$t('general.status')}`,
        field: 'state',
        formatFn: (item: any) => this.$t(`general.${item}`),
        sortable: true
      },
      {
        label: `${this.$t('general.actions')}`,
        field: 'actions'
      }
    ];
  }

  //Funcion invocada para ejecutar la edicion
  edit(input: PreRegister) {
    if (input.state == PreRegisterStatusEnum.CUBED || input.state == PreRegisterStatusEnum.RECEIVED) {
      this.$swal({
        title: `${this.$t('general.warning')}`,
        text: `${this.$t('general.notEditPreregister')}`,
        icon: 'warning'
      });
      return;
    }
    this.$router.push({ path: 'warehouse-preregister', query: { type: input.type, number: input.number } });
  }

  //Funcion invocada para obtener una lista de todos los pre registros que se han realizado
  async findAll() {
    try {
      this.isLoading = true;

      const filters = {
        from: this.filters.from,
        to: this.filters.to,
        type: this.filters.type.id
      };

      const res = await this.findAllByDateAndType.execute(filters);
      this.preRegisterList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Funcion invocada para obtener un pre registro concreto
  async show(input: PreRegister) {
    try {
      this.isLoading = true;
      const res = await this.findByPk.execute({
        type: input.type,
        number: input.number
      });
      this.form = res;
      this.isLoading = false;
      this.showModal = true;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Funcion para obtener un preregistro especifico por medio de los parametros de numero y tipo
  getPreRegisterByNumberAndType(input: PreRegister) {
    this.$router.push({ name: 'historyPanel', query: { type: input.type, number: input.number } });
  }

  async seeDocument(input: PreRegister) {
    this.isLoading = true;
    const res = await this.findByPk.execute({
      type: input.type,
      number: input.number
    });
    this.form = res;
    this.isLoading = false;
    this.$bvModal.show('preRegisterDocModal');
  }
}
